<template>
  <div id="pptresource">
    <div class="left">
      <div class="title">
        <span>+</span>
        <b style="cursor: pointer" @click="addCourses = true">添加授课过程</b>
      </div>
      <ul class="classList">
        <li v-for="(item, index) in list" :key="index">
          <div class="head">
            <img
              class="line"
              src="../../img/板块图标 - 副本_slices/矢量智能对象(3).png"
              alt=""
            />
            <span>{{ item.catalog_name }}</span>
            <el-dropdown
              trigger="click"
              placement="bottom-start"
              style="height: 50/96in; display: flex"
            >
              <span
                class="el-dropdown-link"
                style="display: flex; align-items: center"
                ><img
                  class="more"
                  src="../../img/板块图标 - 副本_slices/更  多 (1) 拷贝 6.png"
                  style="width: 0.2rem"
                  alt=""
              /></span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>
                  <span
                    @click="
                      bigEdito = true;
                      getEdit(item.id, item.catalog_name);
                    "
                    >编辑课程</span
                  >
                  <img
                    src="../../img/板块图标 - 副本_slices/编辑.png"
                    alt=""
                    style="width: 0.1rem; margin-left: 0.1rem"
                /></el-dropdown-item>
                <el-dropdown-item>
                  <span
                    @click="
                      smallCatalog = true;
                      getid(item.id);
                    "
                    >添加课程</span
                  >
                  <img
                    src="../../img/板块图标 - 副本_slices/复制.png"
                    alt=""
                    style="width: 0.1rem; margin-left: 0.1rem"
                /></el-dropdown-item>
                <el-dropdown-item>
                  <span @click="delBig(item.id)">删除课程</span>
                  <img
                    src="../../img/板块图标 - 副本_slices/删除 拷贝 5.png"
                    alt=""
                    style="width: 0.1rem; margin-left: 0.1rem"
                /></el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <ul
            class="smallList"
            v-for="(item1, index1) in item.catalog"
            :key="index1"
          >
            <li
              @click="num = index + String(index1)"
              :class="{ active: num == index + String(index1) }"
            >
              <img
                class="left_img"
                src="../../img/板块图标 - 副本_slices/矢量智能对象.png"
                alt=""
              />
              <span>{{ item1.catalog_name }}</span>
              <div class="right_img">
                <img
                  @click="
                    leftbj(item1.id, item1.catalog_name);
                    smallEdito = true;
                  "
                  src="../../img/板块图标 - 副本_slices/矢量智能对象(1).png"
                  alt=""
                />
                <img
                  @click="leftDel(item1.id)"
                  src="../../img/板块图标 - 副本_slices/矢量智能对象(2).png"
                  alt=""
                />
              </div>
            </li>
          </ul>
        </li>
      </ul>
    </div>

    <main>
      <header>
        <ul>
          <li
            @click="
              three = 0;
              topptLocal();
            "
            :class="{ active: three == 0 }"
          >
            本地上传
          </li>
          <li
            @click="
              three = 1;
              topptPersonal();
            "
            :class="{ active: three == 1 }"
          >
            个人资源导入
          </li>
          <li
            @click="
              three = 2;
              topptSystem();
            "
            :class="{ active: three == 2 }"
          >
            系统资源导入
          </li>
        </ul>
      </header>
      <div class="main">
        <router-view></router-view>
      </div>
    </main>
    <el-dialog
      title="添加授课过程"
      :visible.sync="addCourses"
      class="addCourse"
    >
      <div class="box">
        <el-input
          v-model="bigcourse"
          placeholder="请输入授课过程名称"
        ></el-input>
        <div class="addcourseBtn">
          <el-button @click="addCourses = false">取消</el-button>
          <el-button type="primary" @click="addCourse()">确认</el-button>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      title="添加授课过程小节"
      :visible.sync="smallCatalog"
      class="addCourse"
    >
      <div class="box">
        <el-input
          v-model="smallcourse"
          placeholder="请输入授课过程名称"
        ></el-input>
        <div class="addcourseBtn">
          <el-button @click="smallCatalog = false">取消</el-button>
          <el-button type="primary" @click="smallAdd()">确认</el-button>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      title="授课过程名称修改"
      :visible.sync="bigEdito"
      class="addCourse"
    >
      <div class="box">
        <el-input v-model="bigEdits" :placeholder="oldBigEdit"></el-input>
        <div class="addcourseBtn">
          <el-button @click="bigEdito = false">取消</el-button>
          <el-button type="primary" @click="bigEdit()">确认</el-button>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      title="授课小节名称修改"
      :visible.sync="smallEdito"
      class="addCourse"
    >
      <div class="box">
        <el-input v-model="smallEdits" :placeholder="oldSmallEdit"></el-input>
        <div class="addcourseBtn">
          <el-button @click="smallEdito = false">取消</el-button>
          <el-button type="primary" @click="smallEdit()">确认</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import * as util from "@/unit/network";

export default {
  data() {
    return {
      num: 0,
      three: 0,
      list: "",
      csid: this.$route.query.csid,
      addCourses: false, // 添加授课过程
      bigcourse: "", // 授课过程名称
      smallCatalog: false,
      smallcourse: "",
      smallId: "",
      // 左侧边栏编辑
      bigEdito: false,
      bigEdits: "",
      oldBigEdit: "",
      smallEdito: false,
      smallEdits: "",
      oldSmallEdit: "",
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      util.post("/catalog", { csid: this.$route.query.csid }, (res) => {
        console.log(res.data);
        this.list = res.data;
        this.csid = this.$route.query.csid;
        this.topptLocal();
      });
    },
    topptLocal() {
      this.$router.push({
        path: "/main/addscene/pictureresource/picturelocal",
        query: {
          csid: this.csid,
          dataid: this.$route.query.dataid,
        },
      });
    },
    topptPersonal() {
      //   this.$router.push("/main/addscene/pptresource/pptpersonal");
    },
    topptSystem() {
      //   this.$router.push("/main/addscene/pptresource/pptsystem");
    },
    delBig(id) {
      util.post(
        "/catalogDel",
        {
          id: id,
        },
        (res) => {
          if (res.code == 1) {
            this.getList();
          }
        }
      );
    },
    leftDel(id) {
      util.post("/catalogDel", { id: id }, (res) => {
        if (res.code == 1) {
          this.getList();
        }
      });
    },
    // 添加授课过程
    addCourse() {
      if (this.bigcourse == "") {
        return;
      } else {
        util.post(
          "/catalogAdd",
          {
            catalog_name: this.bigcourse,
            csid: this.$route.query.csid,
            pid: 0,
            order: 0,
          },
          (res) => {
            console.log(res);
            if (res.code == 1) {
              this.addCourses = false;
              this.getList();
            }
          }
        );
      }
    },
    getid(id) {
      this.smallId = id;
    },
    smallAdd() {
      if (this.smallCatalog == "") {
        return;
      } else {
        util.post(
          "/catalogAdd",
          {
            catalog_name: this.smallcourse,
            csid: this.$route.query.csid,
            pid: this.smallId,
            order: 0,
          },
          (res) => {
            console.log(res);
            if (res.code == 1) {
              this.smallCatalog = false;
              this.getList();
            }
          }
        );
      }
    },
    getEdit(id, names) {
      this.bigeditid = id;
      this.oldBigEdit = names;
    },
    bigEdit() {
      util.post(
        "/catalogEdit",
        {
          catalog_name: this.bigEdits,
          id: this.bigeditid,
          order: 0,
        },
        (res) => {
          if (res.code == 1) {
            this.bigEdito = false;
            this.getList();
          }
        }
      );
    },
    leftbj(id, names) {
      this.smalleditid = id;
      this.oldSmallEdit = names;
      console.log(id, names);
    },
    smallEdit() {
      util.post(
        "/catalogEdit",
        {
          catalog_name: this.smallEdits,
          id: this.smalleditid,
          order: 0,
        },
        (res) => {
          if (res.code == 1) {
            this.smallEdito = false;
            this.getList();
          }
        }
      );
    },
  },
};
</script>
<style lang="less" scoped>
#pptresource {
  display: flex;
  height: 100%;
  .left {
    width: 2.38rem /* 238/100 */;
    height: 100%;
    border: 1px solid #cdcdcd;
    border-top: none;
    border-bottom: none;
    .title {
      height: 0.44rem /* 44/100 */;
      border-bottom: 1px solid #cdcdcd;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding-left: 0.14rem /* 14/100 */;
      background-color: #e5e7ea;
      span {
        display: inline-block;
        width: 16/96in;
        height: 16/96in;
        background-color: #1c5eff;
        color: #fff;
        border-radius: 50%;
        text-align: center;
        line-height: 16/96in;
      }
      b {
        font-size: 0.14rem /* 14/100 */;
        font-weight: bold;
        margin-left: 0.08rem /* 8/100 */;
        color: #131313;
      }
    }
    .classList {
      font-size: 0.14rem /* 14/100 */;
      font-weight: 500;
      color: #000000;
      line-height: 0.63rem /* 63/100 */;
      .head {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-right: 0.17rem /* 17/100 */;
        cursor: pointer;
        span {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 100%;
          color: #131313;
          font-size: 18px;
          font-weight: bold;
        }
        .line {
          width: 18px;
          margin: 0 7px 0 8px;
        }
      }
      .smallList {
        .active {
          font-weight: bold;
        }
        li:hover {
          font-weight: bold;
        }
        li {
          display: flex;
          align-items: center;
          padding: 0 15px 0 33px;
          line-height: 40px;
          cursor: pointer;
          .left_img {
            width: 16px;
            margin-right: 6px;
          }
          span {
            width: 100%;
            font-size: 14px;
            color: #131313;
          }
          .right_img {
            // width: 36/96in;
            display: flex;
            img:first-child {
              margin-right: 9px;
            }
          }
        }
      }
    }
  }
  main {
    width: 100%;
    height: 100%;
    header {
      border-bottom: 1/96in solid #cdcdcd;
      padding-left: 57px;
      ul {
        display: flex;
        height: 43/96in;
        li {
          display: flex;
          align-items: center;
          margin-right: 73px;
          color: #131313;
          font-size: 16px;
          height: 100%;
          border-bottom: 3px solid transparent;
          cursor: pointer;
        }
        .active {
          color: #1c5eff;
          border-bottom: 3px solid #1c5eff;
        }
      }
    }
    .main {
      width: 100%;
      height: calc(100% - 50.9px);
    }
  }
}
</style>